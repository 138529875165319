:root {
  --font-family: "Mycall Gelion, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji";
  --main-layer-background: #fff;
  --second-layer-background: #f4f4f9;
  --line-color: #efefef;
  --primary-color: #110d60;
  --primary-hover-color: #0b083e;
  --primary-text-color: #fff;
  --expressive-button: #110d60;
  --expressive-button-hover: #0b083e;
  --expressive-button-focus: #0b083e;
  --expressive-button-disabled: #433be4;
  --expressive-button-text-color: #fff;
  --secondary-button: transparent;
  --secondary-button-border: #110d60;
  --secondary-button-hover: #0b083e;
  --secondary-button-dark: #be0040;
  --secondary-button-disabled: #433be4;
  --secondary-button-text-color: #fff;
  --secondary-text-color-hover: #fff;
  --destructive-dark: #be0040;
}

/*# sourceMappingURL=index.4cebdb21.css.map */
