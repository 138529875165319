:root {
    --font-family: 'Mycall Gelion, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji';

    --main-layer-background: #ffffff;
    --second-layer-background: #f4f4f9;
    --line-color: #efefef;

    --primary-color: #110D60;
    --primary-hover-color: #0b083e;
    --primary-text-color: #ffffff;

    --expressive-button: #110D60;
    --expressive-button-hover: #0b083e;
    --expressive-button-focus: #0b083e;
    --expressive-button-disabled: #433be4;
    --expressive-button-text-color: #ffffff;

    --secondary-button: transparent;
    --secondary-button-border: #110D60;
    --secondary-button-hover: #0b083e;
    --secondary-button-dark:  #be0040;
    --secondary-button-disabled: #433be4;
    --secondary-button-text-color: #ffffff;
    --secondary-text-color-hover: #ffffff;

    --destructive-dark: #be0040;


/*    color: {*/
/*    core: {*/
/*    black: '#222222',*/
/*destructiveDark: '#be0040',*/
/*expressiveButton: '#110D60',*/
/*expressiveButtonDisabled: '#433be4',*/
/*expressiveButtonFocus: '#0b083e',*/
/*expressiveButtonHover: '#0b083e',*/
/*expressiveButtonTextColor: '#ffffff',*/
/*lineColor: '#efefef',*/
/*mainLayerBackground: '#ffffff',*/
/*monthItemColor: '#6D02A3',*/
/*monthItemTextColor: '#000000',*/
/*outerBorderColor: '#f4f4f9',*/
/*primary: '#110D60',*/
/*primaryDisabled: '#e8e8ed',*/
/*primaryHover: '#0b083e',*/
/*primaryTextColor: '#ffffff',*/
/*secondaryButton: 'transparent',*/
/*secondaryButtonBorder: '#110D60',*/
/*secondaryButtonDark: '#be0040',*/
/*secondaryButtonDisabled: '#433be4',*/
/*secondaryButtonHover: '#0b083e',*/
/*secondaryButtonTextColor: '#ffffff',*/
/*secondaryButtonTextColorHover: '#ffffff',*/
/*secondaryLayerBackground: '#f4f4f9',*/
/*transparent: 'transparent',*/
/*white: '#ffffff'*/
/*},*/
/*font: {*/
/*    fontFamily:*/
/*            'Mycall Gelion, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;'*/
/*}*/


}
